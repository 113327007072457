import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, CircularProgress, Typography } from "@material-ui/core";
import { PatientDataAndDosingIcon } from "../../Components/Icons";
import clsx from "clsx";
import PatientInfoAndDosingDialog from "./Dialogs/PatientInfoAndDosingDialog";
import Instructions from "./Instructions";
import Chart from "./Chart";
import Notify from "../../Components/Notification";
import { usePapaParse } from "react-papaparse";
import { getPatientInfoObject } from "./PatientInfo";

const useStyles = makeStyles((theme) => ({
  TextField: {
    margin: theme.spacing(3),
  },
  patDataform: {
    padding: 20,
  },
  patDataPane: {
    padding: 30,
    borderRight: "1px solid #949494",
  },
  dosingPane: {
    padding: 30,
  },
  saveBtnDiv: {
    padding: 20,
  },
  saveBtn: {
    float: "right",
  },
  patientDosingBtnSm: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  patientDosingBtnLg: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  leftPane: {
    flex: 1,
    paddingLeft: 20,
    paddingTop: 20,
    [theme.breakpoints.down("md")]: {
      padding: 20,
    },
  },
  midPane: {
    flex: 11,
    padding: 20,
  },
  chartPane: {
    display: "flex",
    overflowX: "auto",
  },
  progressAndChartItem: {
    textAlign: "center",
    marginBottom: "100px",
  },
  progressDiv: {
    transform: "translateY(200px)",
  },
}));

const SimulationTool = () => {
  const classes = useStyles();

  const [patientDataDialogOpen, setPatientDataDialogOpen] = useState(false);

  const [displayChart, setDisplayChart] = useState(false);

  const [notifySaveSuccess, setNotifySaveSuccess] = useState(false);

  const [notifyErrorInFetchingData, setNotifyErrorInFetchingData] =
    useState(false);
  const [popRange, setPopRange] = useState([]);
  const [popHi, setPopHi] = useState([]);
  const [popMean, setPopMean] = useState([]);
  const [creatData, setCreatData] = useState([]);
  const [patientConcData, setPatientConcData] = useState([]);
  const [simConcData, setSimConcData] = useState([]);
  const [patientDataDialogTabIndex, setPatientDataDialogTabIndex] = useState(0);
  const [criticalWindowData, setCriticalWindowData] = useState({
    upper: {
      time: 0,
      conc: 0,
    },
    lower: {
      time: 0,
      conc: 0,
    },
    auc: 0,
  });

  const [loadPatientDialogOpen, setLoadPatientDialogOpen] = useState(false);
  const [loadMeasurementsDialog, setLoadMeasurementsDialog] = useState(false);
  const { jsonToCSV } = usePapaParse();

  const handleLoadPatientDialogClose = () => {
    setLoadPatientDialogOpen(false);
  };

  const handleLoadPatientDialogOpen = () => {
    setLoadPatientDialogOpen(true);
  };

  const handleLoadMeasurementsDialogClose = () => {
    setLoadMeasurementsDialog(false);
  };

  const handleLoadMeasurementsDialogOpen = () => {
    setLoadMeasurementsDialog(true);
  };

  const handleSaveSuccessNotificationOpen = () => {
    setNotifySaveSuccess(true);
  };

  const handleSaveSuccessNotificationClose = () => {
    setNotifySaveSuccess(false);
  };

  const handleErrorInFetchingDataOpen = () => {
    setNotifyErrorInFetchingData(true);
    setTimeout(() => {
      setDisplayChart(false);
    }, 1000);
  };

  const handleErrorInFetchingDataClose = () => {
    setNotifyErrorInFetchingData(false);
  };

  // patient data
  const patientDataObj = {
    ...getPatientInfoObject(),
    parameters: [
      {
        time: "",
        conc: "",
        creat: "",
      },
      {
        time: "",
        conc: "",
        creat: "",
      },
      {
        time: "",
        conc: "",
        creat: "",
      },
      {
        time: "",
        conc: "",
        creat: "",
      },
      {
        time: "",
        conc: "",
        creat: "",
      },
    ],
  };
  const [patientData, setPatientData] = useState({
    ...patientDataObj,
  });

  const clearChartData = () => {
    setPopHi([]);
    setPopMean([]);
    setPopRange([]);
    setCreatData([]);
    setPatientConcData([]);
    setSimConcData([]);
  };

  const loadGraphForPatientData = () => {
    setDisplayChart(false);
    handlePatientDataDialogClose();
    clearChartData();

    const calculateBSA = () => {
      let wt = patientData.weight;
      let ht = patientData.height;
      // return 0.007184 * Math.pow(ht, 0.725) * Math.pow(wt, 0.425);
      return Math.sqrt((ht * wt) / 3600).toFixed(3);
    };

    const cleanValuesAndConvertCreatUnits = () => {
      //remove non numeric values from measurements
      // sort by time

      let startTime =
        patientData.parameters && patientData.parameters[0]
          ? new Date(patientData.parameters[0].time).getTime()
          : 0;

      let filteredValues = patientData.parameters
        // remove empty rows
        .filter(({ time, conc, creat }) => {
          return time !== "" && conc !== "" && creat !== "";
        })
        //sort the rows by time
        .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
        // //convert the datetime to hours by calculating the difference of each row's time from the first row
        .map(({ time, conc, creat }) => {
          if (patientData.timeInHours) {
            return {
              time,
              conc,
              creat,
            };
          } else {
            return {
              time: Math.floor(
                (new Date(time).getTime() - startTime) / (1000 * 60 * 60)
              ).toString(),
              conc,
              creat,
            };
          }
        });

      // unit conversion for creat
      if (patientData.creatunitmml) {
        filteredValues = filteredValues.map(({ time, conc, creat }) => {
          return { time, conc, creat: creat * 0.0113 };
        });
      }
      return filteredValues;
    };

    const obj = {
      fit: true,
      sim: true,
      data: {
        name: patientData.identifier,
        sex: patientData.sex === "male" ? 0 : 1,
        race: 0,
        weight: Number(patientData.weight),
        height: Number(patientData.height),
        age: Number(patientData.age),
        bsa: calculateBSA(),
        hypo: patientData.baselinealbumin,
        dose: Number(patientData.dose),
        fload: Number(patientData.load),
        // fmain: Number(patientData.main),
        tload: Number(patientData.loadin),
        tmain: Number(patientData.mainin),
        model: null,
        indication: patientData.indication,
        ciRange: 95,
        ciPop: true,
        tend: Number(patientData.sim),
        auto: Number(patientData.sim) > 0 ? false : true,
        simRes: 1000,
        timeOut: 60,
        procTime: 0,
        popParm: [],
        initKin: [],
        termKin: [],
        observation: [...cleanValuesAndConvertCreatUnits()],
        simulation: [],
        cycle: 1,
        upper: {
          time: Number(patientData.upperat),
          conc: Number(patientData.upperconc),
        },
        lower: {
          time: Number(patientData.lowerat),
          conc: Number(patientData.lowerconc),
        },
        down: patientData.down,
        peff: patientData.peff
        // tInf: 4
      },
    };

    fetch(process.env.REACT_APP_API_ENDPOINT, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Error fetching data");
        }
        return res.json();
      })
      .then((res) => {
        let popHiExtracted = [];
        let popMeanExtracted = [];
        let simConcExtracted = [];

        let rangeData = res.simulation
          // filter out all the 0 values as these are plotted against a logarithmic axes.
          .filter(({ time, conc, cpop, cIlo, cIhi }) => {
            return +cpop !== 0 && +cIlo !== 0 && +cIhi !== 0;
          })
          .map(({ time, conc, cpop, cIlo, cIhi }) => {
            popHiExtracted.push([time, cIhi]);
            popMeanExtracted.push([time, cpop]);
            simConcExtracted.push([time, conc]);
            return [time, cIlo, cIhi];
          });

        setPopHi(popHiExtracted);
        setPopMean(popMeanExtracted);
        setPopRange(rangeData);
        setSimConcData(simConcExtracted);
        setCriticalWindowData({
          upper: res.upper,
          lower: res.lower,
          auc: res.auc,
        });

        if (!res.err && !res.simulation.length) {
          throw new Error(res.err);
        }
      })
      .catch((err) => {
        console.error("error", err);
        handleErrorInFetchingDataOpen();
      });

    let creat = obj.data.observation.map((obs) => [
      Number(obs.time),
      //Number(obs.creat)
      patientData.creatunitmml
        ? Math.round(Number(Number(obs.creat) / 0.0113))
        : Number(obs.creat),
    ]);
    //sort obs based on time
    creat = creat.sort((a, b) => a[0] - b[0]);
    setCreatData(creat);

    let conc = obj.data.observation.map((obs) => [
      Number(obs.time),
      Number(obs.conc),
    ]);
    setPatientConcData(conc);

    // check the patientData object for all the fields and set the displayChart flag to true
    if (patientData.identifier) {
      //for now
      setDisplayChart(true);
    }
  };

  const handlePatientDataLoad = (parsedData, source) => {
    setPatientData({
      ...parsedData,
    });
  };

  const handlePatientDataInputChange =
    (name) =>
    ({ target: { value } }) => {
      if (name === "indication") {
        switch (value) {
          case "Leukemia":
            setPatientData({
              ...patientData,
              dose: 5,
              load: 10,
              loadin: 0.5,
              main: 90,
              mainin: 23.5,
              [name]: value,
            });
            return;
          case "Osteosarcoma":
            setPatientData({
              ...patientData,
              dose: 12,
              load: 100,
              loadin: 4,
              main: 0,
              mainin: 0,
              [name]: value,
            });
            return;
          case "Lymphoma":
            setPatientData({
              ...patientData,
              dose: 1,
              load: 100,
              loadin: 4,
              main: 0,
              mainin: 0,
              [name]: value,
            });
            return;
          default:
            return;
        }
      }

      setPatientData({
        ...patientData,
        [name]: value,
      });
    };

  const handleHtWtUnitToggle = () => {
    const newUnitFlag = !patientData.metricunits;
    let newWeight = 0;
    let newHeight = 0;

    if (newUnitFlag) {
      if (patientData.weight !== 0) {
        newWeight = (patientData.weight / 2.205).toFixed(3);
      }
      if (patientData.height !== 0) {
        newHeight = (patientData.height * 2.54).toFixed(3);
      }
    } else {
      if (patientData.weight !== 0) {
        newWeight = (patientData.weight * 2.205).toFixed(3);
      }
      if (patientData.height !== 0) {
        newHeight = (patientData.height / 2.54).toFixed(3);
      }
    }

    setPatientData({
      ...patientData,
      metricunits: newUnitFlag,
      weight: newWeight,
      height: newHeight,
    });
  };

  const handleCreatUnitChange = () => {
    const isCreatUnitMML = !patientData.creatunitmml;
    setPatientData({
      ...patientData,
      creatunitmml: isCreatUnitMML,
    });
  };

  const handleTimeInHoursChange = () => {
    const timeInHours = !patientData.timeInHours;
    setPatientData({
      ...patientData,
      timeInHours: timeInHours,
    });
  };

  const handleCellValueChange = (index) => (event) => {
    const { name, value } = event.target;
    const params = [...patientData.parameters];
    params[index] = {
      ...params[index],
      [name]: value,
    };
    setPatientData({
      ...patientData,
      parameters: params,
    });
  };

  const handleAddRow = () => {
    const intData = {
      time: "",
      conc: "",
      creat: "",
    };
    // setIntTableData([...intTableData, intData]);
    setPatientData({
      ...patientData,
      parameters: [...patientData.parameters, intData],
    });
  };

  const handleAddMeasurementsToPatientData = (
    measurements,
    measTimeInhours,
    defaultCreatUnitInmml
  ) => {
    setPatientData({
      ...patientData,
      timeInHours: measTimeInhours,
      parameters: measurements,
      creatunitmml: defaultCreatUnitInmml,
    });
  };

  const handleRemoveRow = (index) => () => {
    const params = [...patientData.parameters];
    params.splice(index, 1);
    setPatientData({
      ...patientData,
      parameters: params,
    });
  };

  const handlePatientDataDialogOpen = (tabIndex) => () => {
    setPatientDataDialogTabIndex(tabIndex);
    setPatientDataDialogOpen(true);
  };

  const handlePatientDataDialogClose = () => {
    setPatientDataDialogOpen(false);
  };

  const getDate = () =>
    new Date().toJSON().slice(0, 10).split("-").reverse().join("/");

  const handlePatientDataSubmit = () => {
    const ls_key = "mtx-local-data-" + patientData.identifier;
    if (localStorage.length && localStorage.getItem(ls_key)) {
      localStorage.removeItem(ls_key);
    }
    const dataToSave = {
      ...patientData,
      dateSaved: getDate(),
    };
    localStorage.setItem(ls_key, JSON.stringify(dataToSave));

    handleSaveSuccessNotificationOpen();
  };

  const handleClearData = () => {
    setPatientData({
      ...patientDataObj,
    });
  };

  const handleDownload = (dataToDownload) => () => {
    //exclude parameters and dateSaved
    let { parameters, dateSaved, ...jsonData } = patientData;
    if (dataToDownload === "patientInfo") {
      let patient_fields = Object.keys(jsonData);
      let patient_data = Object.values(jsonData);
      //export patient info
      const csvPatientData = jsonToCSV([patient_fields, patient_data]);
      exportCSVData(patientData["identifier"] + "_patientInfo", csvPatientData);
    } else if (dataToDownload === "measurements") {
      let dosing_fields = ["time", "conc", "creat"];
      let dosing_data = [];
      parameters.forEach(({ time, conc, creat }) => {
        if (!patientData.timeInHours) {
          let dateObj = new Date(time);
          const formattedTime =
            [
              dateObj.getMonth() + 1,
              dateObj.getDate(),
              dateObj.getFullYear(),
            ].join("/") +
            " " +
            [dateObj.getHours(), dateObj.getMinutes()].join(":");
          dosing_data.push([formattedTime, conc, creat]);
        } else {
          dosing_data.push([time, conc, creat]);
        }
      });
      //export dosing info
      const csvDosingData = jsonToCSV([dosing_fields, ...dosing_data]);
      exportCSVData(patientData["identifier"] + "_measurements", csvDosingData);
    }
  };

  const exportCSVData = (title, data) => {
    // Create link and download
    const link = document.createElement("a");
    const file = new Blob([data], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = `${title}.csv`;
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const showLoadingIcon = () => {
    return !(
      popRange.length &&
      popHi.length &&
      simConcData.length &&
      popMean.length
    );
  };

  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item sm className={clsx(classes.leftPane, classes.pane)}>
          <Button
            className={clsx(classes.patientDosingBtnSm)}
            variant="contained"
            color="primary"
            onClick={handlePatientDataDialogOpen(0)}
          >
            <PatientDataAndDosingIcon fillColor={"#fff"} />
            <Typography>START</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handlePatientDataDialogOpen(0)}
            color="primary"
            startIcon={
              <PatientDataAndDosingIcon
                fillColor="#fff"
                width="24"
                height="24"
              />
            }
            className={classes.patientDosingBtnLg}
          >
            START
          </Button>
        </Grid>
        <Grid item xs={12} className={clsx(classes.midPane, classes.pane)}>
          {/* main chart block */}
          {displayChart ? (
            <Grid item xs={12} className={classes.progressAndChartItem}>
              {showLoadingIcon() ? (
                <div className={classes.progressDiv}>
                  <CircularProgress
                    size={60}
                    thickness={2}
                    className={classes.progress}
                  />
                </div>
              ) : (
                <Chart
                  patientData={patientData}
                  popRange={popRange}
                  popHi={popHi}
                  creatData={creatData}
                  simConcData={simConcData}
                  patientConcData={patientConcData}
                  popMean={popMean}
                  criticalWindowData={criticalWindowData}
                />
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <div className="chartLoading">
                <Instructions />
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>

      <PatientInfoAndDosingDialog
        patientDataDialogOpen={patientDataDialogOpen}
        handlePatientDataDialogClose={handlePatientDataDialogClose}
        patientData={patientData}
        handlePatientDataInputChange={handlePatientDataInputChange}
        handleHtWtUnitToggle={handleHtWtUnitToggle}
        handleCreatUnitChange={handleCreatUnitChange}
        handleTimeInHoursChange={handleTimeInHoursChange}
        handlePatientDataSubmit={handlePatientDataSubmit}
        handleCellValueChange={handleCellValueChange}
        handleAddRow={handleAddRow}
        handleAddMeasurementsToPatientData={handleAddMeasurementsToPatientData}
        handleRemoveRow={handleRemoveRow}
        handlePatientDataLoad={handlePatientDataLoad}
        loadGraphForPatientData={loadGraphForPatientData}
        handleClearData={handleClearData}
        handleDownload={handleDownload}
        tabIndex={patientDataDialogTabIndex}
        loadPatientDialogOpen={loadPatientDialogOpen}
        handleLoadPatientDialogOpen={handleLoadPatientDialogOpen}
        handleLoadPatientDialogClose={handleLoadPatientDialogClose}
        loadMeasurementsDialog={loadMeasurementsDialog}
        handleLoadMeasurementsDialogOpen={handleLoadMeasurementsDialogOpen}
        handleLoadMeasurementsDialogClose={handleLoadMeasurementsDialogClose}
      />

      <Notify
        variant="success"
        message="Patient data saved successfully"
        open={notifySaveSuccess}
        handleClose={handleSaveSuccessNotificationClose}
      />
      <Notify
        variant="error"
        message="Error fetching data. Please try again later."
        open={notifyErrorInFetchingData}
        handleClose={handleErrorInFetchingDataClose}
      />
    </Fragment>
  );
};

export default SimulationTool;
